import React from "react"
import Topic from "../../components/Topic"

const SettlementAgencies = () => (
  <Topic id="settlement_agencies">
    <p>
      Settlement agencies (sometimes called the Welcome Centres) provide
      immigrant services for newcomers to Canada. These government funded
      agencies offer resources to help you adjust to your new life in Canada.
      Settlement services are often free and they are always confidential.
      Connecting with a settlement agency early on can be very beneficial. Many
      settlement agencies have staff or volunteers who speak languages other
      than English. If you go to a settlement agency and they do not have the
      service or language they need, the staff will help you find another agency
      that does.
    </p>
    <p>
      Some agencies have certain eligibility requirements that you have to meet
      to use their services. Some agencies are only funded to assist Government
      Assisted Refugees. Others serve all newcomers, but some of the specific
      programs are only open to convention refugees and permanent residents. It
      is always a good idea to contact the agency first to clarify if you can
      use their services and possibly make an appointment before your visit.
      Some of the services provided by Settlement agencies include:
    </p>
    <ul className="topic__list">
      <li>Registering for English as a Second Language (ESL) classes</li>
      <li>Finding a place to live and apply for subsidized housing</li>
      <li>
        Finding a job and referrals to foreign credential assessment or training
        programs
      </li>
      <li>
        Getting information about other community services, schools and health
        care
      </li>
      <li>Registering children to school or find childcare</li>
      <li>Interpretation and translation of documents</li>
      <li>Filling out forms and applications</li>
    </ul>
    <p>
      There are several videos in this section that explain what a settlement
      agency does. You can also use the links provided to locate an agency near
      you.
    </p>
  </Topic>
)

export default SettlementAgencies
